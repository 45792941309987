import { Capacitor } from "@capacitor/core";

export const streamApikey = import.meta.env.VITE_STREAM_KEY ?? "xyndvxnzrkh4";

export const mixpanelToken = "f41e5f9ab418cd98a75adbf6ecf4da36";

const iosApiLocalhost = "http://localhost:8000";
const androidApiLocalhost = "http://10.0.2.2:8000";
export const apiUrl =
  import.meta.env.VITE_API_URL ??
  (Capacitor.getPlatform() === "android"
    ? androidApiLocalhost
    : iosApiLocalhost);

export const revenuecatApiKey = {
  ios: "appl_tFnRpFOEPJpYMbVMGWyuGVGEMLd",
  android: "goog_VJgSvAZqeanOvORLPCxMZqNddmB",
}[Capacitor.getPlatform()];

export const sentryDsn =
  "https://daee6ca46099ee9147c38e0722523292@o4504282426114048.ingest.us.sentry.io/4507464484585472";

export const tosUrl = "https://www.mentory.app/nutzungsbedingungen";
export const privacyUrl = "https://www.mentory.app/privacy";
